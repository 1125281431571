import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from "./style.module.css";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useState } from "react";
import { useEffect } from "react";

const charactersImg = [
  { title: "character01", img: "character01" },
  { title: "character02", img: "character02" },
  { title: "character03", img: "character03" },
  { title: "character04", img: "character04" },
  { title: "character05", img: "character05" },
  { title: "character06", img: "character06" },
  { title: "character07", img: "character07" },
  { title: "character08", img: "character08" },
];

export default function Character() {
  const [windowWidth] = useWindowSize();

  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slideToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
  });

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      slidesToShow:
        windowWidth > 1000
          ? 4
          : windowWidth > 700
          ? 3
          : windowWidth > 300
          ? 2
          : 1,
    }));
  }, [windowWidth]);

  return (
    <div className={styles.frame}>
      <div className={styles.sliderWrapper}>
        <Slider {...settings}>
          {charactersImg.map((character, index) => (
            <div key={index} className={styles.imgs}>
              <img
                src={require("./assets/" + character.img + ".png")}
                alt={character.title}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
