import S from "./style.module.css";

export default function rarityItemMaps({ items }) {
  return (
    <div className={S.tabWrapper}>
      <div className={S.title}>{items.title}</div>
      <div className={S.mapWrapper}>
        <div className={S.mapLine}>
          {items.items.map((item) => {
            return (
              <div className={S.itemWrapper} key={item.name}>
                <div
                  className={S.imgBox}
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div className={S.rarity}>{item.rarity}</div>
                </div>
                <div className={S.name}>{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
