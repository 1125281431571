import earthImg from "./background/earth.png";
import moonImg from "./background/moon.png";
import marsImg from "./background/mars.png";
import sunImg from "./background/sun.png";
import galaxyImg from "./background/galaxy.png";

import alienImg from "./type/alien.png";
import brownImg from "./type/brown.png";
import darkImg from "./type/dark.png";

import emotionNothing from "./emotion/emotion_nothing.png";
import emotionjoy from "./emotion/emotion_joy.png";
import emotionAnger from "./emotion/emotion_anger.png";
import emotionsorrow from "./emotion/emotion_sorrow.png";
import emotionPleasure from "./emotion/emotion_pleasure.png";
import emotionSurprise from "./emotion/emotion_surprise.png";
import emotionHungry from "./emotion/emotion_hungry.png";
import emotionYummy from "./emotion/emotion_yummy.png";
import emotionNonsense from "./emotion/emotion_nonsense.png";
import emotionSorry from "./emotion/emotion_sorry.png";
import emotionPopopo from "./emotion/emotion_popopo.png";
import emotionFatal from "./emotion/emotion_fatal.png";
import emotionFud from "./emotion/emotion_fud.png";
import emotionFomo from "./emotion/emotion_fomo.png";
import emotionBeep from "./emotion/emotion_beep.png";

import imgNone from "./spacesuit/hat_none.png";
import suitWhite from "./spacesuit/spacesuit_white.png";
import suitBlack from "./spacesuit/spacesuit_black.png";
import suitRed from "./spacesuit/spacesuit_red.png";
import suitOrange from "./spacesuit/spacesuit_orange.png";
import suitYellow from "./spacesuit/spacesuit_yellow.png";
import suitGreen from "./spacesuit/spacesuit_green.png";
import suitBlue from "./spacesuit/spacesuit_blue.png";
import suitNavy from "./spacesuit/spacesuit_navy.png";
import suitPurple from "./spacesuit/spacesuit_purple.png";
import suitDollar from "./spacesuit/spacesuit_dollar.png";
import suitSilver from "./spacesuit/spacesuit_silver.png";
import suitGold from "./spacesuit/spacesuit_gold.png";
import suitBitcoin from "./spacesuit/spacesuit_bitcoin.png";

import eyeRed from "./eye/eyes_red.png";
import eyeBlue from "./eye/eyes_blue.png";
import eyeGreen from "./eye/eyes_green.png";
import eyeYellow from "./eye/eyes_yellow.png";
import eyeNothing from "./eye/eyes_nothing.png";
import eyeJoy from "./eye/eyes_joy.png";
import eyeAnger from "./eye/eyes_anger.png";
import eyeSorrow from "./eye/eyes_sorrow.png";
import eyePleasure from "./eye/eyes_pleasure.png";
import eyeSurprise from "./eye/eyes_surprise.png";
import eyeCuriosity from "./eye/eyes_curiosity.png";
import eyeBling from "./eye/eyes_bling.png";
import eyeLovely from "./eye/eyes_lovely.png";
import eyeDollar from "./eye/eyes_dollar.png";
import eyeSilver from "./eye/eyes_silver.png";
import eyeGold from "./eye/eyes_gold.png";
import eyeBitcoin from "./eye/eyes_bitcoin.png";

import helmetWhite from "./helmet/white.png";
import helmetBlack from "./helmet/black.png";
import helmetDollar from "./helmet/dollar.png";
import helmetSilver from "./helmet/silver.png";
import helmetGold from "./helmet/gold.png";
import helmetBitcoin from "./helmet/bitcoin.png";

import weaponGun from "./weapon/toy.png";
import weaponLaser from "./weapon/laser.png";
import weaponAlien from "./weapon/alien.png";
import weaponRed from "./weapon/red.png";
import weaponBlue from "./weapon/blue.png";
import weaponGreen from "./weapon/green.png";
import weaponYellow from "./weapon/yellow.png";
import weaponSilver from "./weapon/silver.png";
import weaponGold from "./weapon/gold.png";
import weaponBitcoin from "./weapon/bitcoin.png";

export const backgroundGOG = {
  title: "BACKGROUND",
  items: [
    { name: "EARTH", image: earthImg, rarity: "59.0%" },
    { name: "MOON", image: moonImg, rarity: "22.4%" },
    { name: "MARS", image: marsImg, rarity: "11.4%" },
    { name: "SUN", image: sunImg, rarity: "5.6%" },
    { name: "GALAXY", image: galaxyImg, rarity: "1.6%" },
  ],
};

export const typeGOG = {
  title: "type",
  items: [
    { name: "BROWN APE", image: brownImg, rarity: "64.0%" },
    { name: "DARK APE", image: darkImg, rarity: "33.4%" },
    { name: "ALIEN", image: alienImg, rarity: "2.6%" },
  ],
};

export const emotionGOG = {
  title: "EMOTION",
  items: [
    { name: "NOTHING", image: emotionNothing, rarity: "13.4%" },
    { name: "JOY", image: emotionjoy, rarity: "7.6%" },
    { name: "ANGER", image: emotionAnger, rarity: "12.2%" },
    { name: "SORROW", image: emotionsorrow, rarity: "10.8%" },
    { name: "PLEASURE", image: emotionPleasure, rarity: "6.6%" },
    { name: "SURPRISE", image: emotionSurprise, rarity: "6.0%" },
    { name: "HUNGRY", image: emotionHungry, rarity: "6.0%" },
    { name: "YUMMY", image: emotionYummy, rarity: "5.2%" },
    { name: "NONSENSE", image: emotionNonsense, rarity: "7.6%" },
    { name: "SORRY", image: emotionSorry, rarity: "6.2%" },
    { name: "POPOPO", image: emotionPopopo, rarity: "5.2%" },
    { name: "FATAL", image: emotionFatal, rarity: "7.8%" },
    { name: "FUD", image: emotionFud, rarity: "1.4%" },
    { name: "FOMO", image: emotionFomo, rarity: "1.4%" },
    { name: "BEEP BEEP", image: emotionBeep, rarity: "1.2%" },
  ],
};

export const spaceSuitGOG = {
  title: "SPACESUIT",
  items: [
    { name: "NONE", image: imgNone, rarity: "16.0%" },
    { name: "WHITE SUIT", image: suitWhite, rarity: "9.0%" },
    { name: "BLACK SUIT", image: suitBlack, rarity: "11.6%" },
    { name: "RED SUIT", image: suitRed, rarity: "9.0%" },
    { name: "ORANGE SUIT", image: suitOrange, rarity: "6.0%" },
    { name: "YELLOW SUIT", image: suitYellow, rarity: "9.2%" },
    { name: "GREEN SUIT", image: suitGreen, rarity: "6.2%" },
    { name: "BLUE SUIT", image: suitBlue, rarity: "9.0%" },
    { name: "NAVY SUIT", image: suitNavy, rarity: "11.0%" },
    { name: "PURPLE SUIT", image: suitPurple, rarity: "6.8%" },
    { name: "DOLLAR SUIT", image: suitDollar, rarity: "3.8%" },
    { name: "SILVER SUIT", image: suitSilver, rarity: "1.0%" },
    { name: "GOLD SUIT", image: suitGold, rarity: "0.8%" },
    { name: "BITCOIN SUIT", image: suitBitcoin, rarity: "0.6%" },
  ],
};

export const eyeGOG = {
  title: "SPACESUIT",
  items: [
    { name: "NORMAL RED", image: eyeRed, rarity: "11.2%" },
    { name: "NORMAL BLUE", image: eyeBlue, rarity: "10.2%" },
    { name: "NORMAL GREEN", image: eyeGreen, rarity: "9.6%" },
    { name: "NORMAL YELLOW", image: eyeYellow, rarity: "7.2%" },
    { name: "NOTHING", image: eyeNothing, rarity: "8.4%" },
    { name: "JOY FROG EYES", image: eyeJoy, rarity: "9.0%" },
    { name: "ANGER FROG EYES", image: eyeAnger, rarity: "6.6%" },
    { name: "SORROW FROG EYES", image: eyeSorrow, rarity: "8.0%" },
    { name: "PLEASURE FROG EYES", image: eyePleasure, rarity: "8.2%" },
    { name: "SURPRISE FROG EYES", image: eyeSurprise, rarity: "4.4%" },
    { name: "CURIOSITY\nFROG EYES", image: eyeCuriosity, rarity: "4.2%" },
    { name: "BLING FROG EYES", image: eyeBling, rarity: "4.8%" },
    { name: "LOVELY FROG EYES", image: eyeLovely, rarity: "4.2%" },
    { name: "DOLLAR FROG EYES", image: eyeDollar, rarity: "2.0%" },
    { name: "SILVER STAR FROG EYES", image: eyeSilver, rarity: "1.0%" },
    { name: "GOLD STAR FROG EYES", image: eyeGold, rarity: "0.6%" },
    { name: "BITCOIN FROG EYES", image: eyeBitcoin, rarity: "0.4%" },
  ],
};

export const helmetGOG = {
  title: "ASTRONAUT HELMET",
  items: [
    { name: "NONE", image: imgNone, rarity: "44.2%" },
    { name: "WHITE HELMET", image: helmetWhite, rarity: "20.8%" },
    { name: "BLACK HELMET", image: helmetBlack, rarity: "17.4%" },
    { name: "DOLLAR HELMET", image: helmetDollar, rarity: "10.4%" },
    { name: "SILVER HELMET", image: helmetSilver, rarity: "5.2%" },
    { name: "GOLD HELMET", image: helmetGold, rarity: "1.6%" },
    { name: "BITCOIN HELMET", image: helmetBitcoin, rarity: "0.4%" },
  ],
};

export const weaponGOG = {
  title: "WEAPON",
  items: [
    { name: "NONE", image: imgNone, rarity: "30.6%" },
    { name: "TOY GUN", image: weaponGun, rarity: "19.8%" },
    { name: "LASER BLASTER", image: weaponLaser, rarity: "14.8%" },
    { name: "RED LIGHT SABER", image: weaponRed, rarity: "4.2%" },
    { name: "BLUE LIGHT SABER", image: weaponBlue, rarity: "4.4%" },
    { name: "GREEN LIGHT SABER", image: weaponGreen, rarity: "4.4%" },
    { name: "YELLOW LIGHT SABER", image: weaponYellow, rarity: "4.0%" },
    { name: "SILVER SWORD", image: weaponSilver, rarity: "3.2%" },
    { name: "GOLD SWORD", image: weaponGold, rarity: "1.6%" },
    { name: "BITCOIN GAUNTLET", image: weaponBitcoin, rarity: "0.2%" },
  ],
};
