import bgBlack from "./background/black.png";
import bgWhite from "./background/white.png";
import bgRed from "./background/red.png";
import bgOrange from "./background/orange.png";
import bgYellow from "./background/yellow.png";
import bgGreen from "./background/green.png";
import bgBlue from "./background/blue.png";
import bgNavy from "./background/navy.png";
import bgPurple from "./background/purple.png";
import bgSpring from "./background/spring.png";
import bgSummer from "./background/summer.png";
import bgAutumn from "./background/autumn.png";
import bgWinter from "./background/winter.png";
import bgRainbow from "./background/rainbow.png";
import bgLand from "./background/land.png";
import bgSea from "./background/sea.png";
import bgSky from "./background/sky.png";
import bgTropical from "./background/tropical.png";
import bgPolar from "./background/polar.png";
import bgDesert from "./background/desert.png";
import bgGrassland from "./background/grassland.png";
import bgJungle from "./background/jungle.png";

import skDark from "./skin/dark.png";
import skLignt from "./skin/light.png";
import skRed from "./skin/red.png";
import skOrange from "./skin/orange.png";
import skYellow from "./skin/yellow.png";
import skGreen from "./skin/green.png";
import skBlue from "./skin/blue.png";
import skNavy from "./skin/navy.png";
import skPurple from "./skin/purple.png";
import skCat from "./skin/cat.png";
import skDog from "./skin/dog.png";
import skFish from "./skin/fish.png";
import skGiraffe from "./skin/giraffe.png";
import skGorilla from "./skin/gorilla.png";
import skLeopard from "./skin/leopard.png";
import skMilkCow from "./skin/milk.png";
import skMonkey from "./skin/monkey.png";
import skTiger from "./skin/tiger.png";
import skZebra from "./skin/zebra.png";
import skRock from "./skin/rock.png";
import skTree from "./skin/tree.png";
import skRainbow from "./skin/rainbow.png";
import skBronze from "./skin/bronze.png";
import skSilver from "./skin/silver.png";
import skGold from "./skin/gold.png";

import euzzi from "./eyes/uzzi.png";
import echumzi from "./eyes/chumzi.png";
import eriro from "./eyes/riro.png";
import enani from "./eyes/nani.png";
import eoj from "./eyes/oj.png";
import enoidea from "./eyes/noidea.png";
import epleasure from "./eyes/pleasure.png";
import eanger from "./eyes/anger.png";
import esorrow from "./eyes/sorrow.png";
import ejoy from "./eyes/joy.png";
import eannoying from "./eyes/annoying.png";
import erage from "./eyes/rage.png";
import efatigue from "./eyes/fatigue.png";
import epeace from "./eyes/peace.png";
import erespect from "./eyes/respect.png";
import ejealousy from "./eyes/jealousy.png";
import edepression from "./eyes/depression.png";
import egreed from "./eyes/greed.png";
import efear from "./eyes/fear.png";
import edeath from "./eyes/death.png";
import etears from "./eyes/bloody.png";
import epunk from "./eyes/punk.png";

import muzzi from "./mouth/uzzi.png";
import mhyeok from "./mouth/hyeok.png";
import moj from "./mouth/oj.png";
import mriro from "./mouth/riro.png";
import mchumzi from "./mouth/chumzi.png";
import mnani from "./mouth/nani.png";
import mpleasure from "./mouth/pleasure.png";
import manger from "./mouth/anger.png";
import msorrow from "./mouth/sorrow.png";
import mjoy from "./mouth/joy.png";
import mjeer from "./mouth/jeer.png";
import mkidding from "./mouth/kidding.png";
import mpatience from "./mouth/patience.png";
import msurprise from "./mouth/surprise.png";
import mhungry from "./mouth/hungry.png";
import mfull from "./mouth/full.png";
import mshut from "./mouth/shut.png";
import msimple from "./mouth/simple.png";
import mdisappointed from "./mouth/disappointed.png";
import mgreed from "./mouth/greed.png";
import mfear from "./mouth/fear.png";
import mdeath from "./mouth/death.png";
import mcat from "./mouth/cat.png";
import mcatcula from "./mouth/catcula.png";
import mgoblin from "./mouth/goblin.png";
import mthick from "./mouth/thick.png";
import mzipper from "./mouth/zipper.png";

import fpleasure from "./Feyes/pleasure.png";
import fanger from "./Feyes/anger.png";
import fsorrow from "./Feyes/sorrow.png";
import fjoy from "./Feyes/joy.png";
import fred from "./Feyes/red.png";
import fblue from "./Feyes/blue.png";
import fgreen from "./Feyes/green.png";
import frespect from "./Feyes/respect.png";
import fjealousy from "./Feyes/jealousy.png";
import fdepression from "./Feyes/depression.png";
import fgreed from "./Feyes/greed.png";
import ffear from "./Feyes/fear.png";
import fscar from "./Feyes/scar.png";
import fdouble from "./Feyes/double.png";
import fcyberpunk from "./Feyes/cyberpunk.png";
import fpeace from "./Feyes/peace.png";

import wblack from "./wardrobe/black.png";
import wwhite from "./wardrobe/white.png";
import wred from "./wardrobe/red.png";
import worange from "./wardrobe/orange.png";
import wyellow from "./wardrobe/yellow.png";
import wgreen from "./wardrobe/green.png";
import wblue from "./wardrobe/blue.png";
import wnavy from "./wardrobe/navy.png";
import wpurple from "./wardrobe/purple.png";
import wdeveloper from "./wardrobe/developer.png";
import wartist from "./wardrobe/artist.png";
import woffice from "./wardrobe/office.png";
import wbusiness from "./wardrobe/business.png";
import wschoolm from "./wardrobe/schoolm.png";
import wschoolw from "./wardrobe/schoolw.png";
import whawk from "./wardrobe/hawk.png";
import wcleopatra from "./wardrobe/cleopatra.png";
import wdjango from "./wardrobe/django.png";
import wdoyotomi from "./wardrobe/doyotomi.png";
import wflintstone from "./wardrobe/flintstone.png";
import wgenghis from "./wardrobe/genghis.png";
import wguan from "./wardrobe/guan.png";
import wharry from "./wardrobe/harry.png";
import whattori from "./wardrobe/hattori.png";
import whuang from "./wardrobe/huang.png";
import wjack from "./wardrobe/jack.png";
import wmusashi from "./wardrobe/musashi.png";
import wnapoleon from "./wardrobe/napoleon.png";
import wragnar from "./wardrobe/ragnar.png";
import wramesses from "./wardrobe/ramesses.png";
import wsun from "./wardrobe/sun.png";
import wjeon from "./wardrobe/jeon.png";
import wyi from "./wardrobe/yi.png";
import wcyber from "./wardrobe/cyberpunk.png";
import wsteam from "./wardrobe/steampunk.png";

import none from "./hat/none.png";
import hblack from "./hat/black.png";
import hwhite from "./hat/white.png";
import hred from "./hat/red.png";
import horange from "./hat/orange.png";
import hyellow from "./hat/yellow.png";
import hgreen from "./hat/green.png";
import hblue from "./hat/blue.png";
import hnavy from "./hat/navy.png";
import hpurple from "./hat/purple.png";
import h4leaf from "./hat/4leaf.png";
import hdeveloper from "./hat/developer.png";
import hartist from "./hat/artist.png";
import hoffice from "./hat/office.png";
import hbusiness from "./hat/business.png";
import hsalary from "./hat/salary.png";
import hcurly from "./hat/curly.png";
import hshaved from "./hat/shaved.png";
import hthick from "./hat/thick.png";
import hhawk from "./hat/hawk.png";
import hcleopatra from "./hat/cleopatra.png";
import hdharma from "./hat/dharma.png";
import hdjango from "./hat/django.png";
import hdoyotomi from "./hat/doyotomi.png";
import hflintstone from "./hat/flintstone.png";
import hgenghis from "./hat/genghis.png";
import hguan from "./hat/guan.png";
import hharry from "./hat/harry.png";
import hhattori from "./hat/hattori.png";
import hhuang from "./hat/huang.png";
import hjack from "./hat/jack.png";
import hmusashi from "./hat/musashi.png";
import hnapoleon from "./hat/napoleon.png";
import hragnar from "./hat/ragnar.png";
import hramesses from "./hat/ramesses.png";
import hsun from "./hat/sun.png";
import hyeopo from "./hat/yeopo.png";
import hhong from "./hat/hong.png";
import hjeon from "./hat/jeon.png";
import hyi from "./hat/yi.png";
import hlandpunk from "./hat/landpunk.png";
import hskypunk from "./hat/skypunk.png";
import hcyber from "./hat/cyberpunk.png";
import hsteam from "./hat/steampunk.png";

import athin from "./accessary/thin.png";
import athick from "./accessary/thick.png";
import abeard from "./accessary/beard.png";
import amuch from "./accessary/much.png";
import atoo from "./accessary/too.png";
import acharm from "./accessary/charm.png";
import afatal from "./accessary/fatal.png";
import afreckle from "./accessary/freckle.png";
import acheek from "./accessary/cheek.png";
import aforehead from "./accessary/forehead.png";
import aeyepatch from "./accessary/eyepatch.png";
import apiercing from "./accessary/piercing.png";
import amosaic from "./accessary/mosaic.png";
import afierce from "./accessary/fierce.png";
import astylish from "./accessary/stylish.png";
import alovely from "./accessary/lovely.png";
import aharry from "./accessary/harry.png";
import amask from "./accessary/mask.png";
import akf94 from "./accessary/kf94.png";
import agas from "./accessary/gas.png";
import ared from "./accessary/red.png";
import ablue from "./accessary/blue.png";
import aneon from "./accessary/neon.png";
import aodd from "./accessary/odd.png";
import ataegeuk from "./accessary/taegeuk.png";
import asilver from "./accessary/silver.png";
import a18k from "./accessary/18k.png";
import a24k from "./accessary/24k.png";
import acyborg from "./accessary/cyborg.png";

import pcarrot from "./weapon/weapon_carrot.png";
import pcucumber from "./weapon/weapon_cucumber.png";
import pradish from "./weapon/weapon_radish.png";
import peggplant from "./weapon/weapon_eggplant.png";
import ppotato from "./weapon/weapon_potato.png";
import psweet from "./weapon/weapon_sweet.png";
import pcorn from "./weapon/weapon_corn.png";
import plettuce from "./weapon/weapon_lettuce.png";
import papple from "./weapon/weapon_apple.png";
import pcherry from "./weapon/weapon_cherry.png";
import pbanana from "./weapon/weapon_banana.png";
import pgrape from "./weapon/weapon_grape.png";
import pmango from "./weapon/weapon_mango.png";
import pkiwi from "./weapon/weapon_kiwi.png";
import porange from "./weapon/weapon_orange.png";
import pbrown from "./weapon/weapon_brown.png";
import pgray from "./weapon/weapon_gray.png";
import ptoy from "./weapon/weapon_toy.png";
import ppen from "./weapon/weapon_pen.png";
import pspoon from "./weapon/weapon_spoon.png";
import phand from "./weapon/weapon_hand.png";
import pbriefcase from "./weapon/weapon_briefcase.png";
import pspanner from "./weapon/weapon_spanner.png";
import phammer from "./weapon/weapon_hammer.png";
import pelectric from "./weapon/weapon_electric.png";
import pdyson from "./weapon/weapon_dyson.png";
import palcohol from "./weapon/weapon_alcohol.png";
import pboxing from "./weapon/weapon_boxing.png";
import pbomb from "./weapon/weapon_bomb.png";
import pchicken from "./weapon/weapon_bone.png";
import pleg from "./weapon/weapon_leg.png";
import pcolt from "./weapon/weapon_colt.png";
import pfirelock from "./weapon/weapon_firelock.png";
import pfolding from "./weapon/weapon_folding.png";
import pgreen from "./weapon/weapon_green.png";
import pjoseon from "./weapon/weapon_joseon.png";
import pkatana from "./weapon/weapon_katana.png";
import posiris from "./weapon/weapon_osiris.png";
import pruyi from "./weapon/weapon_ruyi.png";
import psauk from "./weapon/weapon_sauk.png";
import pshuriken from "./weapon/weapon_shuriken.png";
import pstone from "./weapon/weapon_stone.png";
import psword from "./weapon/weapon_sword.png";
import pelder from "./weapon/weapon_elder.png";
import pviking from "./weapon/weapon_viking.png";
import pice from "./weapon/weapon_ice.png";
import pdark from "./weapon/weapon_dark.png";
import pfire from "./weapon/weapon_fire.png";
import pcyberpunk from "./weapon/weapon_cyberpunk.png";
import psteampunk from "./weapon/weapon_steampunk.png";

export const backgroundGOP = {
  title: "BACKGROUND",
  items: [
    { name: "BLACK", image: bgBlack, rarity: "11.0%" },
    { name: "WHITE", image: bgWhite, rarity: "10.7%" },
    { name: "RED", image: bgRed, rarity: "6.8%" },
    { name: "ORANGE", image: bgOrange, rarity: "6.9%" },
    { name: "YELLOW", image: bgYellow, rarity: "7.2%" },
    { name: "GREEN", image: bgGreen, rarity: "7.0%" },
    { name: "BLUE", image: bgBlue, rarity: "7.3%" },
    { name: "NAVY", image: bgNavy, rarity: "7.0%" },
    { name: "PURPLE", image: bgPurple, rarity: "6.8%" },
    { name: "SPRING", image: bgSpring, rarity: "4.8%" },
    { name: "SUMMER", image: bgSummer, rarity: "4.6%" },
    { name: "AUTUMN", image: bgAutumn, rarity: "5.1%" },
    { name: "WINTER", image: bgWinter, rarity: "4.6%" },
    { name: "RAINBOW", image: bgRainbow, rarity: "2.1%" },
    { name: "LAND", image: bgLand, rarity: "2.1%" },
    { name: "SEA", image: bgSea, rarity: "1.9%" },
    { name: "SKY", image: bgSky, rarity: "1.9%" },
    { name: "TROPICAL", image: bgTropical, rarity: "0.6%" },
    { name: "POLAR", image: bgPolar, rarity: "0.5%" },
    { name: "DESERT", image: bgDesert, rarity: "0.4%" },
    { name: "GRASSLAND", image: bgGrassland, rarity: "0.5%" },
    { name: "JUNGLE", image: bgJungle, rarity: "0.4%" },
  ],
};

export const skinGOP = {
  title: "SKIN",
  items: [
    { name: "DARK", image: skDark, rarity: "10.4%" },
    { name: "LIGHT", image: skLignt, rarity: "9.6%" },
    { name: "RED", image: skRed, rarity: "5.6%" },
    { name: "ORANGE", image: skOrange, rarity: "5.7%" },
    { name: "YELLOW", image: skYellow, rarity: "6.0%" },
    { name: "GREEN", image: skGreen, rarity: "6.2%" },
    { name: "BLUE", image: skBlue, rarity: "5.8%" },
    { name: "NAVY", image: skNavy, rarity: "5.8%" },
    { name: "PURPLE", image: skPurple, rarity: "6.5%" },
    { name: "CAT", image: skCat, rarity: "3.2%" },
    { name: "DOG", image: skDog, rarity: "3.1%" },
    { name: "FISH", image: skFish, rarity: "2.8%" },
    { name: "GIRAFFE", image: skGiraffe, rarity: "3.2%" },
    { name: "GORILLA", image: skGorilla, rarity: "3.1%" },
    { name: "LEOPARD", image: skLeopard, rarity: "2.5%" },
    { name: "MILK COW", image: skMilkCow, rarity: "3.8%" },
    { name: "MONKEY", image: skMonkey, rarity: "2.7%" },
    { name: "TIGER", image: skTiger, rarity: "3.3%" },
    { name: "ZEBRA", image: skZebra, rarity: "2.9%" },
    { name: "ROCK", image: skRock, rarity: "1.5%" },
    { name: "TREE", image: skTree, rarity: "2.4%" },
    { name: "RAINBOW", image: skRainbow, rarity: "2.0%" },
    { name: "BRONZE", image: skBronze, rarity: "0.8%" },
    { name: "SILVER", image: skSilver, rarity: "0.7%" },
    { name: "GOLD", image: skGold, rarity: "0.3%" },
  ],
};

export const eyeGOP = {
  title: "GENERAL EYES",
  items: [
    { name: "UZZI", image: euzzi, rarity: "10.2%" },
    { name: "CHUMZI", image: echumzi, rarity: "10.5%" },
    { name: "RIRO", image: eriro, rarity: "10.8%" },
    { name: "NANI", image: enani, rarity: "9.8%" },
    { name: "OJ", image: eoj, rarity: "10.5%" },
    { name: "NO IDEA", image: enoidea, rarity: "5.3%" },
    { name: "RLEASURE", image: epleasure, rarity: "5.8%" },
    { name: "ANGER", image: eanger, rarity: "5.2%" },
    { name: "SORROW", image: esorrow, rarity: "6.3%" },
    { name: "JOY", image: ejoy, rarity: "6.3%" },
    { name: "ANNOYING", image: eannoying, rarity: "2.4%" },
    { name: "RAGE", image: erage, rarity: "2.0%" },
    { name: "FATIGUE", image: efatigue, rarity: "2.5%" },
    { name: "PEACE", image: epeace, rarity: "2.7%" },
    { name: "RESPECT", image: erespect, rarity: "2.2%" },
    { name: "JEALOUSY", image: ejealousy, rarity: "2.4%" },
    { name: "DEPRESSION", image: edepression, rarity: "2.1%" },
    { name: "GREED", image: egreed, rarity: "0.9%" },
    { name: "FEAR", image: efear, rarity: "0.9%" },
    { name: "DEATH", image: edeath, rarity: "0.6%" },
    { name: "BLOODY TEARS", image: etears, rarity: "0.5%" },
    { name: "PUNK PUNK", image: epunk, rarity: "0.3%" },
  ],
};

export const mouthGOP = {
  title: "MOUTH",
  items: [
    { name: "UZZI", image: muzzi, rarity: "5.8%" },
    { name: "HYEOK", image: mhyeok, rarity: "5.4%" },
    { name: "OJ", image: moj, rarity: "4.8%" },
    { name: "RIRO", image: mriro, rarity: "5.5%" },
    { name: "CHUMZI", image: mchumzi, rarity: "5.5%" },
    { name: "NANI", image: mnani, rarity: "5.9%" },
    { name: "RLEASURE", image: mpleasure, rarity: "4.6%" },
    { name: "ANGER", image: manger, rarity: "4.8%" },
    { name: "SORROW", image: msorrow, rarity: "4.9%" },
    { name: "JOY", image: mjoy, rarity: "3.9%" },
    { name: "JEER", image: mjeer, rarity: "4.0%" },
    { name: "KIDDING", image: mkidding, rarity: "3.8%" },
    { name: "PATIENCE", image: mpatience, rarity: "3.4%" },
    { name: "SURPRISE", image: msurprise, rarity: "3.4%" },
    { name: "HUNGRY", image: mhungry, rarity: "3.7%" },
    { name: "FULL", image: mfull, rarity: "4.0%" },
    { name: "SHUT THE MOUTH", image: mshut, rarity: "3.6%" },
    { name: "SIMPLE IGNORANCE", image: msimple, rarity: "3.4%" },
    { name: "DISAPPOINTED", image: mdisappointed, rarity: "3.5%" },
    { name: "GREED", image: mgreed, rarity: "2.9%" },
    { name: "FEAR", image: mfear, rarity: "3.0%" },
    { name: "DEATH", izmage: mdeath, rarity: "3.4%" },
    { name: "CAT", izmage: mcat, rarity: "2.5%" },
    { name: "CATCULA", izmage: mcatcula, rarity: "1.8%" },
    { name: "GOBLIN", image: mgoblin, rarity: "1.2%" },
    { name: "THICK", image: mthick, rarity: "0.9%" },
    { name: "ZIPPER", image: mzipper, rarity: "0.5%" },
  ],
};

export const FEYEGOP = {
  title: "GROG EYES",
  items: [
    { name: "PLEASURE", image: fpleasure, rarity: "15.4%" },
    { name: "ANGER", image: fanger, rarity: "16.5%" },
    { name: "SORROW", image: fsorrow, rarity: "16.5%" },
    { name: "JOY", image: fjoy, rarity: "16.5%" },
    { name: "LOVE_RED", image: fred, rarity: "5.2%" },
    { name: "LOVE_BLUE", image: fblue, rarity: "4.6%" },
    { name: "LOVE_GREEN", image: fgreen, rarity: "5.0%" },
    { name: "PEACE", image: fpeace, rarity: "3.3%" },
    { name: "RESPECT", image: frespect, rarity: "3.3%" },
    { name: "JEALOUSY", image: fjealousy, rarity: "3.7%" },
    { name: "DEPRESSION", image: fdepression, rarity: "3.6%" },
    { name: "GREED", image: fgreed, rarity: "2.3%" },
    { name: "FEAR", image: ffear, rarity: "2.4%" },
    { name: "SCAR", image: fscar, rarity: "0.9%" },
    { name: "DOUBLE SCAR", image: fdouble, rarity: "0.5%" },
    { name: "CYBERPUNK", image: fcyberpunk, rarity: "0.2%" },
  ],
};

export const wardrobeGOP = {
  title: "WARDROBE",
  items: [
    { name: "BLACK", image: wblack, rarity: "10.9%" },
    { name: "WHITE", image: wwhite, rarity: "9.4%" },
    { name: "RED", image: wred, rarity: "5.9%" },
    { name: "ORANGE", image: worange, rarity: "6.0%" },
    { name: "YELLOW", image: wyellow, rarity: "5.1%" },
    { name: "GREEN", image: wgreen, rarity: "6.0%" },
    { name: "BLUE", image: wblue, rarity: "6.2%" },
    { name: "NAVY", image: wnavy, rarity: "6.2%" },
    { name: "PURPLE", image: wpurple, rarity: "6.0%" },
    { name: "DEVELOPER", image: wdeveloper, rarity: "4.4%" },
    { name: "ARTIST", image: wartist, rarity: "3.4%" },
    { name: "OFFICE WORKER", image: woffice, rarity: "4.1%" },
    { name: "BUSINESS MAN", image: wbusiness, rarity: "4.1%" },
    { name: "SCHOOL UNIFORM M", image: wschoolm, rarity: "4.0%" },
    { name: "SCHOOL UNIFORM W", image: wschoolw, rarity: "3.7%" },
    { name: "BLACK HAWK", image: whawk, rarity: "1.0%" },
    { name: "CLEOPATRA", image: wcleopatra, rarity: "0.7%" },
    { name: "DJANGO", image: wdjango, rarity: "1.0%" },
    { name: "DOYOTOMI HIDEYOSI", image: wdoyotomi, rarity: "0.9%" },
    { name: "FLINTSTONE", image: wflintstone, rarity: "0.7%" },
    { name: "GENGHIS KHAN", image: wgenghis, rarity: "0.8%" },
    { name: "GUAN YU", image: wguan, rarity: "1.1%" },
    { name: "HARRY POTTER", image: wharry, rarity: "1.0%" },
    { name: "HATTORY HANZO", image: whattori, rarity: "0.7%" },
    { name: "HUANG FEI HONG", image: whuang, rarity: "0.7%" },
    { name: "JACK SPARROW", image: wjack, rarity: "0.7%" },
    { name: "MUSASHI", image: wmusashi, rarity: "1.0%" },
    { name: "NAPOLEON", image: wnapoleon, rarity: "0.7%" },
    { name: "RAGNAR LODBROK", image: wragnar, rarity: "0.8%" },
    { name: "RAMESSES", image: wramesses, rarity: "0.8%" },
    { name: "SUN WUKONG", image: wsun, rarity: "0.8%" },
    { name: "JEON WOOCHI", image: wjeon, rarity: "0.6%" },
    { name: "YI SUNSIN", image: wyi, rarity: "0.5%" },
    { name: "CYBERPUNK", image: wcyber, rarity: "0.1%" },
    { name: "STEAMPUNK", image: wsteam, rarity: "0.1%" },
  ],
};

export const hatGOP = {
  title: "HAT",
  items: [
    { name: "NONE", image: none, rarity: "13.3%" },
    { name: "BLACK CAP", image: hblack, rarity: "8.0%" },
    { name: "WHITE CAP", image: hwhite, rarity: "7.9%" },
    { name: "RED CAP", image: hred, rarity: "3.8%" },
    { name: "ORANGE CAP", image: horange, rarity: "4.1%" },
    { name: "YELLOW CAP", image: hyellow, rarity: "3.9%" },
    { name: "GREEN CAP", image: hgreen, rarity: "3.6%" },
    { name: "BLUE CAP", image: hblue, rarity: "4.7%" },
    { name: "NAVY CAP", image: hnavy, rarity: "3.8%" },
    { name: "PURPLE CAP", image: hpurple, rarity: "4.7%" },
    { name: "4LEAF CLOVER CAP", image: h4leaf, rarity: "2.1%" },
    { name: "DEVELOPER", image: hdeveloper, rarity: "2.1%" },
    { name: "ARTIST", image: hartist, rarity: "2.0%" },
    { name: "OFFICE WORKER", image: hoffice, rarity: "1.9%" },
    { name: "BUSINESS MAN", image: hbusiness, rarity: "1.6%" },
    { name: "SALARY LUPIN", image: hsalary, rarity: "1.6%" },
    { name: "CURLY HAIR", image: hcurly, rarity: "2.0%" },
    { name: "SHAVED HAIR", image: hshaved, rarity: "2.0%" },
    { name: "THICK HAIR", image: hthick, rarity: "1.8%" },
    { name: "BLACK HAWK", image: hhawk, rarity: "1.4%" },
    { name: "CLEOPATRA", image: hcleopatra, rarity: "1.3%" },
    { name: "HDARMA", image: hdharma, rarity: "1.1%" },
    { name: "DJANGO", image: hdjango, rarity: "1.1%" },
    { name: "DOYOTOMI HIDEYOSI", image: hdoyotomi, rarity: "1.4%" },
    { name: "FLINTSTONE", image: hflintstone, rarity: "1.4%" },
    { name: "GENGHIS KHAN", image: hgenghis, rarity: "1.2%" },
    { name: "GUAN YU", image: hguan, rarity: "1.0%" },
    { name: "HARRY POTTER", image: hharry, rarity: "1.0%" },
    { name: "HATTORY HANZO", image: hhattori, rarity: "1.2%" },
    { name: "HUANG FEI HONG", image: hhuang, rarity: "1.2%" },
    { name: "JACK SPARROW", image: hjack, rarity: "1.1%" },
    { name: "MUSASHI", image: hmusashi, rarity: "1.2%" },
    { name: "NAPOLEON", image: hnapoleon, rarity: "1.0%" },
    { name: "RAGNAR LODBROK", image: hragnar, rarity: "1.2%" },
    { name: "RAMESSES", image: hramesses, rarity: "1.5%" },
    { name: "SUN WUKONG", image: hsun, rarity: "1.0%" },
    { name: "YEOPO", image: hyeopo, rarity: "1.1%" },
    { name: "HONG GILDONG", image: hhong, rarity: "0.8%" },
    { name: "JEON WOOCHI", image: hjeon, rarity: "1.0%" },
    { name: "YI SUNSIN", image: hyi, rarity: "0.8%" },
    { name: "LANDPUNK", image: hlandpunk, rarity: "0.5%" },
    { name: "SKYPUNK", image: hskypunk, rarity: "0.3%" },
    { name: "CYBERPUNK", image: hcyber, rarity: "0.4%" },
    { name: "STEAMPUNK", image: hsteam, rarity: "0.1%" },
  ],
};

export const accessaryGOP = {
  title: "WARDROBE",
  items: [
    { name: "NONE", image: none, rarity: "26.4%" },
    { name: "THIN MUSTACHE", image: athin, rarity: "3.8%" },
    { name: "THICK MUSTACHE", image: athick, rarity: "3.8%" },
    { name: "BEARD", image: abeard, rarity: "4.4%" },
    { name: "MUCH BEARD", image: amuch, rarity: "4.0%" },
    { name: "TOO MUCH BEARD", image: atoo, rarity: "4.2%" },
    { name: "CHARM MOLE", image: acharm, rarity: "2.9%" },
    { name: "FATAL MOLE", image: afatal, rarity: "2.9%" },
    { name: "FRECKLE", image: afreckle, rarity: "2.8%" },
    { name: "CHEEK SCRATCH", image: acheek, rarity: "3.0%" },
    { name: "FOREHEAD SCRATCH", image: aforehead, rarity: "3.2%" },
    { name: "EYEPATCH", image: aeyepatch, rarity: "3.8%" },
    { name: "PIERCING", image: apiercing, rarity: "3.7%" },
    { name: "MOSAIC", image: amosaic, rarity: "3.3%" },
    { name: "FIERCE SUNGLASSES", image: afierce, rarity: "3.0%" },
    { name: "STYLISH SUNGLASSES", image: astylish, rarity: "3.2%" },
    { name: "LOVELY SUNGLASSES", image: alovely, rarity: "3.4%" },
    { name: "HARRY POTTER SUNGLASSES", image: aharry, rarity: "2.5%" },
    { name: "MASK", image: amask, rarity: "2.7%" },
    { name: "KF94", image: akf94, rarity: "2.2%" },
    { name: "GAS MASK", image: agas, rarity: "1.7%" },
    { name: "RED TATTOO", image: ared, rarity: "1.6%" },
    { name: "BLUE TATTOO", image: ablue, rarity: "1.6%" },
    { name: "NEON TATTOO", image: aneon, rarity: "1.2%" },
    { name: "ODD TATTOO", image: aodd, rarity: "1.2%" },
    { name: "TAEGEUK TATTOO", image: ataegeuk, rarity: "1.1%" },
    { name: "SILVER PIERCING", image: asilver, rarity: "1.1%" },
    { name: "18K PIERCING", image: a18k, rarity: "0.7%" },
    { name: "24K PIERCING", image: a24k, rarity: "0.4%" },
    { name: "CYBORG", image: acyborg, rarity: "0.1%" },
  ],
};

export const weaponGOP = {
  title: "WEAPON",
  items: [
    { name: "NONE", image: none, rarity: "10.6%" },
    { name: "CARROT", image: pcarrot, rarity: "3.3%" },
    { name: "CUCUMBER", image: pcucumber, rarity: "3.8%" },
    { name: "RADISH", image: pradish, rarity: "4.0%" },
    { name: "EGGPLANT", image: peggplant, rarity: "4.0%" },
    { name: "POTATO", image: ppotato, rarity: "4.4%" },
    { name: "SWEET POTATO", image: psweet, rarity: "3.9%" },
    { name: "CORN", image: pcorn, rarity: "3.4%" },
    { name: "LETTUCE", image: plettuce, rarity: "3.4%" },
    { name: "APPLE", image: papple, rarity: "3.2%" },
    { name: "CHERRY", image: pcherry, rarity: "2.9%" },
    { name: "BANANA", image: pbanana, rarity: "2.7%" },
    { name: "GRAPE", image: pgrape, rarity: "2.7%" },
    { name: "MANGO", image: pmango, rarity: "2.3%" },
    { name: "KIWI", image: pkiwi, rarity: "2.6%" },
    { name: "ORANGE", image: porange, rarity: "2.6%" },
    { name: "TEDDY BEAR_BROWN", image: pbrown, rarity: "2.3%" },
    { name: "TEDDY BEAR_GRAY", image: pgray, rarity: "2.0%" },
    { name: "TOY HAMMER", image: ptoy, rarity: "1.9%" },
    { name: "PEN", image: ppen, rarity: "2.1%" },
    { name: "SPOON", image: pspoon, rarity: "1.9%" },
    { name: "HAND MIRROR", image: phand, rarity: "2.4%" },
    { name: "BRIEFCASE", image: pbriefcase, rarity: "2.1%" },
    { name: "SPANNER", image: pspanner, rarity: "2.0%" },
    { name: "HAMMER", image: phammer, rarity: "1.7%" },
    { name: "ELECTRIC CHAINSAW", image: pelectric, rarity: "1.8%" },
    { name: "DYSON VACUUM CLEANER", image: pdyson, rarity: "1.9%" },
    { name: "ALCOHOL BOTTME", image: palcohol, rarity: "1.8%" },
    { name: "BOXING GLOVE", image: pboxing, rarity: "1.8%" },
    { name: "BOMB", image: pbomb, rarity: "1.9%" },
    { name: "CHICKEN BONE", image: pchicken, rarity: "1.0%" },
    { name: "CHICKEN LEG", image: pleg, rarity: "1.2%" },
    { name: "COLT SINGLE ACTION ARMY", image: pcolt, rarity: "0.5%" },
    { name: "FIRELOCK", image: pfirelock, rarity: "0.7%" },
    { name: "FOLDING FAN", image: pfolding, rarity: "0.5%" },
    { name: "GREEN DRAGON CRESCENT BIBLE", image: pgreen, rarity: "0.6%" },
    { name: "JOSEON DAGGER", image: pjoseon, rarity: "0.6%" },
    { name: "KATANA", image: pkatana, rarity: "0.7%" },
    { name: "OSIRIS", image: posiris, rarity: "0.8%" },
    { name: "RUYI JINGU BANG", image: pruyi, rarity: "0.8%" },
    { name: "SAUK BOW", image: psauk, rarity: "0.7%" },
    { name: "SHURIKEN", image: pshuriken, rarity: "0.7%" },
    { name: "STONE BAT", image: pstone, rarity: "0.8%" },
    { name: "SWORD OF TRITON", image: psword, rarity: "0.6%" },
    { name: "THE ELDER WAND", image: pelder, rarity: "0.4%" },
    { name: "VIKING BATTLE AXE", image: pviking, rarity: "0.7%" },
    { name: "ICE SWORD", image: pice, rarity: "0.3%" },
    { name: "DARK SWORD", image: pdark, rarity: "0.3%" },
    { name: "FIRE SWORD", image: pfire, rarity: "0.4%" },
    { name: "CYBERPUNK CHAIN", image: pcyberpunk, rarity: "0.1%" },
    { name: "STEAMPUNK CHAIN", image: psteampunk, rarity: "0.0%" },
  ],
};
