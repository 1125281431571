import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styles from "./style.module.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  backgroundColor: "transparent",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    fontWeight: "bold",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiTypography-body1": {
    cursor: "pointer",
    display: "flex",
    "justify-content": "space-between",
    "font-family": '"Baloo 2", "cursive"',
    "font-weight": "800",
    color: "#0c4e2c",
    "font-size": "20px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  "& .MuiTypography-body1": {
    "line-height": "150%",
    "font-family": '"Baloo 2", "cursive"',
    color: "#444",
    "font-weight": "600",
    "word-break": "keep-all",
    padding: "0px 20px 0px",
    transition: "all 1s ease-in-out",
  },
}));

const faqItems = [
  {
    title: "Q Who are the Gaeguneez?",
    description: `They are the guardians who protect the environment from various
  pollutants. Using their mutant power, they fight against the Polluters
  who plague the Earth.`,
  },
  {
    title: "Q How many NFTs will be minted?",
    description: `The total amount of supply will be 5,500: 500 GOG NFTs(Gaeguneez of
      the Galaxy) and 5,000 GOP NFTs(Gaeguneez of the Planet).`,
  },
  {
    title: "Q What is the difference between GOG and GOP?",
    description: `GOG is an early limited edition. Those who own it will benefit the
    most during the expansion of the Gaeguneez ecosystem.`,
  },
  {
    title: "Q What`s the price?",
    description: `200 klay per unit for GOG, and 100 klay per unit for GOP.`,
  },
  {
    title: "Q Where can I buy it?",
    description: `It will be available on OpenSea after minting.`,
  },
  {
    title: "Q Will there be a rarity table?",
    description: `The rarity table will be released after the public minting.`,
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <section className={styles.frame}>
        <h2 className={styles.title}>The Mission Questions</h2>
        <div className={styles.toggleWrapper}>
          <div>
            {faqItems.map((faqItem, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>{faqItem.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faqItem.description}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
        <div className={styles.faqBottom} />
      </section>
    </>
  );
}
