import { useState } from "react";
import RaritiesHeader from "../../../components/RaritiesHeader/RaritiesHeader";
import {
  backgroundGOG,
  emotionGOG,
  eyeGOG,
  helmetGOG,
  spaceSuitGOG,
  typeGOG,
  weaponGOG,
} from "../../../components/RaritiesItems/GOG/gogDatas";
import {
  accessaryGOP,
  backgroundGOP,
  eyeGOP,
  FEYEGOP,
  hatGOP,
  mouthGOP,
  skinGOP,
  wardrobeGOP,
  weaponGOP,
} from "../../../components/RaritiesItems/GOP/gopData";
import RarityItemMaps from "../../../components/RaritiesItems/RarityItemMaps";
import RaritiesTab from "../../../components/RaritiesTab/RaritiesTab";
import styles from "./style.module.css";

export default function RaritiPages() {
  const [currentTabGOG, setCurrentTabGOG] = useState(true);
  const [itemTab, setItemTab] = useState("ALL");

  const handleChangeCurrentTabGOG = (isGOG) => setCurrentTabGOG(isGOG);
  const handleChangeItemTab = (item) => setItemTab(item);

  return (
    <div className={styles.mainWrapper}>
      <main className={styles.sectionWrapper}>
        <RaritiesHeader />
        <RaritiesTab
          currentTabGOG={currentTabGOG}
          itemTab={itemTab}
          handleChangeCurrentTabGOG={handleChangeCurrentTabGOG}
          handleChangeItemTab={handleChangeItemTab}
        />

        <div
          style={{
            marginTop: 100,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <div style={{ width: "100%", maxWidth: "1000px" }}>
            {currentTabGOG && (
              <>
                {(itemTab === "ALL" || itemTab === "BACKGROUND") && (
                  <RarityItemMaps items={backgroundGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "TYPE") && (
                  <RarityItemMaps items={typeGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "EMOTION") && (
                  <RarityItemMaps items={emotionGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "SPACESUIT") && (
                  <RarityItemMaps items={spaceSuitGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "FROG EYES") && (
                  <RarityItemMaps items={eyeGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "ASTRONAUT HELMET") && (
                  <RarityItemMaps items={helmetGOG} />
                )}
                {(itemTab === "ALL" || itemTab === "WEAPON") && (
                  <RarityItemMaps items={weaponGOG} />
                )}
              </>
            )}
            {!currentTabGOG && (
              <>
                {(itemTab === "ALL" || itemTab === "BACKGROUND") && (
                  <RarityItemMaps items={backgroundGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "SKIN") && (
                  <RarityItemMaps items={skinGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "GENERAL EYES") && (
                  <RarityItemMaps items={eyeGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "MOUTH") && (
                  <RarityItemMaps items={mouthGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "FROG EYES") && (
                  <RarityItemMaps items={FEYEGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "WARDROBE") && (
                  <RarityItemMaps items={wardrobeGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "HAT") && (
                  <RarityItemMaps items={hatGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "ACCESSARY") && (
                  <RarityItemMaps items={accessaryGOP} />
                )}
                {(itemTab === "ALL" || itemTab === "WEAPON") && (
                  <RarityItemMaps items={weaponGOP} />
                )}
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
