import twitterIcon from "./assets/twitter.png";
import instaIcon from "./assets/insta.png";
import discordIcon from "./assets/discord.png";
import social from "./assets/social.png";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useState } from "react";

const linkData = [
  {
    linkTo: "https://twitter.com/gaeguneez",
    image: twitterIcon,
    name: "twitter",
  },
  {
    linkTo: "https://www.instagram.com/ggnz.io/",
    image: instaIcon,
    name: "instagram",
  },
  {
    linkTo: "https://discord.com/invite/27np754pdg",
    image: discordIcon,
    name: "discord",
  },
];

export default function SocialIcons() {
  const [windowWidth] = useWindowSize();
  const [showSocial, setShowSocial] = useState(false);

  return (
    <>
      {windowWidth > 600 ? (
        <div
          style={{
            position: "fixed",
            bottom: windowWidth > 600 ? "67px" : "0px",
            right: windowWidth > 600 ? "50px" : "10px",
          }}
        >
          {linkData.map((link, index) => (
            <div
              key={index}
              style={{
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",

                backgroundColor: "#fff",

                marginBottom: 15,
              }}
              onClick={() => window.open(link.linkTo)}
            >
              <img width={24} src={link.image} alt={link.name} />{" "}
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 10,
          }}
        >
          {showSocial &&
            linkData.map((link, index) => (
              <div
                key={index}
                style={{
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundColor: "#fff",
                  marginBottom: 15,
                }}
                onClick={() => window.open(link.linkTo)}
              >
                <img width={24} src={link.image} alt={link.name} />{" "}
              </div>
            ))}
          {!showSocial &&
            linkData.slice(2).map((link, index) => (
              <div
                key={index}
                style={{
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",

                  backgroundColor: "#fff",

                  marginBottom: 15,
                }}
                onClick={() => window.open(link.linkTo)}
              >
                <img width={24} src={link.image} alt={link.name} />{" "}
              </div>
            ))}

          <div
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",

              backgroundColor: "#fdf47f",

              marginBottom: 15,
            }}
            onClick={() => setShowSocial((prev) => !prev)}
          >
            <img width={24} src={social} alt={social} />
          </div>
        </div>
      )}
    </>
  );
}
