import React, { useRef } from "react";
import Header from "../../components/Header/Header";

import Intro from "./Tab/Intro/Intro";
import OriginStory from "./Tab/OriginStory/OriginStory";

import Teams from "./Tab/Teams/Teams";
import Roadmap from "./Tab/RoadMap/Roadmap";
import FAQ from "./Tab/FAQ/FAQ";

import styles from "./style.module.css";
import { useState } from "react";
import RaritiPages from "./raritiesPage/RaritiesPage";
import SocialIcons from "../../components/socialIcon/SocialIcons";
function MainPage() {
  const introRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);
  const faqRef = useRef(null);

  const handleClickGoToHome = () =>
    introRef.current?.scrollIntoView({ behavior: "smooth" });

  const handleClickGoToRoadMap = () =>
    roadmapRef.current?.scrollIntoView({ behavior: "smooth" });
  const handleClickGoToTeam = () =>
    teamRef.current?.scrollIntoView({ behavior: "smooth" });
  const handleClickGoToFaq = () =>
    faqRef.current?.scrollIntoView({ behavior: "smooth" });

  const [currentPageHome, setcurrentPageHome] = useState(true);

  const changePage = (isHome) => setcurrentPageHome(isHome);
  return (
    <>
      <div
        className={styles.mainWrapper}
        style={{
          backgroundColor: currentPageHome ? "rgb(85 194 251)" : "#ffffff",
        }}
      >
        <Header
          isHome
          handleClickGoToHome={handleClickGoToHome}
          handleClickGoToRoadMap={handleClickGoToRoadMap}
          handleClickGoToTeam={handleClickGoToTeam}
          handleClickGoToFaq={handleClickGoToFaq}
          changePage={changePage}
        />

        {currentPageHome && (
          <main className={styles.sectionWrapper}>
            <div className={styles.refWrapper} ref={introRef}>
              <Intro />
            </div>
            <OriginStory />

            <div className={styles.refWrapper} ref={roadmapRef}>
              <Roadmap />
            </div>

            <div className={styles.refWrapper} ref={teamRef}>
              <Teams />
            </div>
            <div className={styles.refWrapper} ref={faqRef}>
              <FAQ />
            </div>
          </main>
        )}

        {!currentPageHome && <RaritiPages />}
      </div>
      <SocialIcons />
    </>
  );
}

export default MainPage;
