import React from "react";
import styles from "./style.module.css";

import num1Img from "./assets/num1.png";
import num2Img from "./assets/num2.png";
import num3Img from "./assets/num3.png";
import num4Img from "./assets/num4.png";
import num5Img from "./assets/num5.png";
import num6Img from "./assets/num6.png";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const roadmapList = [
  {
    img: num1Img,
    title: "Website Launch (1 Jan 2022)",
    details: [
      "Introduction of the Gaeguneez Universe.",
      "Sneak previews of the Gaeguneez NFT.",
    ],
  },
  {
    img: num2Img,
    title: "Pre-Sale (7 Jan 2022)",
    details: [
      "350 out of 500 GOG NFTs will be pre-sold as an early limited edition.",
      "The rarity ranking of the GOG NFTs will be released after the pre-sale.",
    ],
  },
  {
    img: num3Img,
    title: "Public Mint & Rarity Ranking (22 Feb 2022)",
    details: [
      "We will list 3,500 out of 5,000 GOP NFTs on our website for sale to the general public.",
      "The rarity ranking of the GOP NFTs will be released after public minting.",
    ],
  },
  {
    img: num4Img,
    title: `Activate NFT Staking Farm & Release Reward Rate based on Rarity Ranking (Q2 2022)`,
    details: [
      "We will activate the GGNZ token airdrop through staking for the Gaeguneez NFT holders",
      "The higher the rarity rank, the more rewards you receive, with the GOG having the most rewards.",
    ],
  },
  {
    img: num5Img,
    title:
      "Release White Paper Ver.1 & List GGNZ Token on the Exchange (Q3 2022)",
    details: [
      " We will list GGNZ token on the exchange to provide Gaeguneez NFT holders with the opportunities to generate profit.",
    ],
  },
  {
    img: num6Img,
    title: "Build Partnerships for Entering the Metaverse (Q4 2022)",
    details: [
      "We will establish various partnerships in order to facilitate Gaeguneez's entry to the metaverse.",
      "Its process will mostly be basded on the members` consensus.",
    ],
  },
];

export default function Roadmap() {
  const [windowWidth] = useWindowSize();
  return (
    <section className={styles.roadmapFrame}>
      <p className={styles.title}>Roadmap</p>
      <div className={styles.orders} />
      <div className={styles.listsWrapper}>
        {roadmapList.map((list, index) => (
          <div key={index} className={styles.roadmapDetails}>
            <div className={styles.listTitles}>
              {windowWidth > 800 && (
                <img src={list.img} alt={list.title} width={50} height={50} />
              )}
              <div className={styles.number}>{list.title}</div>
            </div>
            {list.details.map((detail, i) => (
              <ul key={i} className={styles.content}>
                <li> {detail} </li>
              </ul>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
