import React from "react";
import styles from "./style.module.css";

import grayImg from "./assets/team-gray.png";
import kevinImg from "./assets/team-kevin.png";
import bongtaeImg from "./assets/team-bongtae.png";
import konyImg from "./assets/team-kony.png";
import uzziImg from "./assets/team-uzzi.png";
import doraImg from "./assets/team-dora.png";
import hannaImg from "./assets/team-hanna.png";
import ojImg from "./assets/team-oj.png";
import xmasImg from "./assets/team-xmas.png";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const membersFirstLine = [
  { name: "GRAY", position: "Tech Leader", img: grayImg },
  { name: "KEVIN", position: "Back-end Engineer", img: kevinImg },
  { name: "BONGTAE", position: "Front-end Engineer", img: bongtaeImg },
  { name: "KONY", position: "Front-end Engineer", img: konyImg },
];

const memberSecondLine = [
  { name: "UZZI", position: "Artist", img: uzziImg },
  { name: "DORA", position: "Designer", img: doraImg },
  { name: "HANNA", position: "UX Designer", img: hannaImg },
];

const memberThirdLine = [
  { name: "OJ", position: "Captain", img: ojImg },
  { name: "XMAS", position: "Project Manager", img: xmasImg },
];

function Teams() {
  const [windowWidth] = useWindowSize();

  return (
    <>
      <div className={styles.teamFrame}>
        <div className={styles.title}>Teams</div>

        <div>
          <div className={styles.memberBox}>
            {membersFirstLine.map((member) => (
              <div className={styles.memberWrapper} key={member.name}>
                <img
                  className={styles.memberImage}
                  src={member.img}
                  alt={member.name}
                  width={windowWidth > 512 ? 217 : windowWidth - 50}
                />
                <div className={styles.memberName}>{member.name}</div>
                <div className={styles.memberPosition}>{member.position}</div>
              </div>
            ))}
          </div>
          <div className={styles.memberBoxSecond}>
            {memberSecondLine.map((member) => (
              <div className={styles.memberWrapper} key={member.name}>
                <img
                  className={styles.memberImage}
                  src={member.img}
                  alt={member.name}
                  width={windowWidth > 512 ? 217 : windowWidth - 80}
                />
                <div className={styles.memberName}>{member.name}</div>
                <div className={styles.memberPosition}>{member.position}</div>
              </div>
            ))}
          </div>
          <div className={styles.memberBoxThird}>
            {memberThirdLine.map((member) => (
              <div className={styles.memberWrapper} key={member.name}>
                <img
                  className={styles.memberImage}
                  src={member.img}
                  alt={member.name}
                  width={windowWidth > 512 ? 217 : windowWidth - 80}
                />
                <div className={styles.memberName}>{member.name}</div>
                <div className={styles.memberPosition}>{member.position}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.teamBottomWrapper}>
        <div className={styles.faqTop} />
        <div className={styles.teamBottom} />
      </div>
    </>
  );
}

export default Teams;
