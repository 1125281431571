import { useWindowSize } from "../../hooks/useWindowSize";
import S from "./style.module.css";

const gogTab = [
  "ALL",
  "BACKGROUND",
  "TYPE",
  "EMOTION",
  "SPACESUIT",
  "FROG EYES",
  "ASTRONAUT HELMET",
  "WEAPON",
];

const gopTab = [
  "ALL",
  "BACKGROUND",
  "SKIN",
  "GENERAL EYES",
  "MOUTH",
  "FROG EYES",
  "WARDROBE",
  "HAT",
  "ACCESSARY",
  "WEAPON",
];
export default function RaritiesTab({
  handleChangeCurrentTabGOG,
  handleChangeItemTab,
  currentTabGOG,
  itemTab,
}) {
  const [windowWidth] = useWindowSize();
  return (
    <div
      style={{
        paddingTop: 50,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: windowWidth,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 30,
          }}
        >
          <div
            className={S.gogButton}
            style={{
              width: 310,
              height: 44,
              borderRadius: 50,
              backgroundColor: currentTabGOG ? "#009B5C" : "white",
              color: currentTabGOG ? "white" : "#cccccc",
            }}
            onClick={() => {
              handleChangeCurrentTabGOG(true);
              handleChangeItemTab("ALL");
            }}
          >
            GOG
          </div>
          <div
            className={S.gogButton}
            style={{
              width: 310,
              height: 44,
              borderRadius: 50,
              backgroundColor: !currentTabGOG ? "#009B5C" : "white",
              color: !currentTabGOG ? "white" : "#cccccc",
            }}
            onClick={() => {
              handleChangeCurrentTabGOG(false);
              handleChangeItemTab("ALL");
            }}
          >
            GOP
          </div>
        </div>
        <div
          className={S.tabWrapper}
          style={{
            display: "flex",
            justifyContent: windowWidth > 1000 ? "center" : "flex-start",
            gap: 8,
            marginTop: 50,
            padding: "10px 20px",
            width: windowWidth,
            overflow: "scroll",
            whiteSpace: "nowrap",
          }}
        >
          {currentTabGOG &&
            gogTab.map((tabItem) => {
              return (
                <div
                  style={{
                    backgroundColor: itemTab === tabItem ? "#FDF47F" : "white",
                    color: itemTab === tabItem ? "black" : "#cccccc",
                  }}
                  className={S.tabItem}
                  onClick={() => handleChangeItemTab(tabItem)}
                  key={tabItem}
                >
                  {tabItem}
                </div>
              );
            })}
          {!currentTabGOG &&
            gopTab.map((tabItem) => {
              return (
                <div
                  style={{
                    backgroundColor: itemTab === tabItem ? "#FDF47F" : "white",
                    color: itemTab === tabItem ? "black" : "#cccccc",
                  }}
                  className={S.tabItem}
                  onClick={() => handleChangeItemTab(tabItem)}
                  key={tabItem}
                >
                  {tabItem}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
