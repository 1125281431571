import React from "react";

import "swiper/css";

import styles from "./style.module.css";

import image01 from "./assets/story-img01.jpg";
import image02 from "./assets/story-img02.jpg";
import image03 from "./assets/story-img03.jpg";
import image04 from "./assets/story-img04.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Character from "../Characters/Character";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useWindowSize } from "../../../../hooks/useWindowSize";
import { useEffect } from "react";

function OriginStory() {
  const [windowWidth] = useWindowSize();
  const stories = [
    {
      img: image01,
      description: `A group of aliens from distant universe come down to earth to investigate its environment and the ecosystem. After their planet Trashmeda was destroyed due to severe pollution, the aliens wandered around the universe for thousands of years in search of a new planet to settle down and finally came to the galaxy.`,
    },
    {
      img: image02,
      description: `While investigating the Earth, they randomly caught frogs and conducted biological experiments on them. They find out that the Earth is in pollution that’s more serious than their planet was. Afraid of getting contaminated, they throw away the frogs at once and flee.`,
    },
    {
      img: image03,
      description: `The abandoned frogs are relieved to be free again, but at the same time become alert to the serious pollution of the Earth. Then one day, the abandoned frogs gradually start to mutate. Is it because when they were caught by the aliens, they were so hungry that they unsuspectingly gobbled up the alien’s baits?`,
    },
    {
      img: image04,
      description: `Soon the mutated frogs come to possess unusual power that has never been seen before. Seeing it as fate's instruction for them to resolve the pollution of the Earth, they start to call themselves the Gaeguneez, and become determined to fight against various pollutants that destroy the Earth. Will the Gaeguneez be able to defeat all the Polluters and protect the Earth's environment? What will happen to their fate in the future?`,
    },
  ];

  const bannerMainCarouselSettings = {
    autoPlay: true,
    renderArrowPrev: () => false,
    renderArrowNext: () => false,
    showArrows: false,
    showStatus: false,
    infiniteLoop: true,
    interval: 3000,
    showThumbs: false,
  };

  useEffect(() => {
    const item = document.querySelector(".carousel");
    item.style.height =
      windowWidth > 800 ? `700px` : windowWidth > 500 ? `600px` : `400px`;
  }, [windowWidth]);

  return (
    <section className={styles.sectionWrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>The Origin Story</div>
      </div>

      <div className={styles.sliderWrapper}>
        <Carousel {...bannerMainCarouselSettings}>
          {stories.map((story, index) => (
            <div className={styles.storyWrapper} key={index}>
              <div className={styles.contents}>
                <img
                  style={{
                    maxWidth: windowWidth > 1000 ? 1000 : windowWidth,
                    borderRadius: 40,
                    padding: 20,
                  }}
                  className={styles.image}
                  src={story.img}
                  alt={story.img + index}
                />

                <div className={styles.description}>
                  <div
                    style={{
                      padding: "0px 20px",
                      fontWeight: 400,
                      fontSize: windowWidth > 1000 ? 20 : 12,
                      lineHeight: "110%",
                      maxWidth: windowWidth > 1000 ? 1000 : windowWidth,
                    }}
                    className={styles.descriptionContents}
                  >
                    {story.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <Character />
      <div className={styles.characterBottom} />
    </section>
  );
}

export default OriginStory;
