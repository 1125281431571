import styles from "./style.module.css";
import ggnzLogo from "./assets/ggnz_logo.png";
import { useWindowSize } from "../../hooks/useWindowSize";

import hamburger from "./assets/hamburger.png";
import outlink from "./assets/exit-top-right.png";
import { useState } from "react";
import { Drawer } from "@mui/material";
const navMenu = [
  {
    title: "HOME",
    linkTo: "/",
    linkToExternalPage: false,
  },
  {
    title: "ROADMAP",
    linkTo: "/roadmap",
    linkToExternalPage: false,
  },
  {
    title: "TEAM",
    linkTo: "/team",
    linkToExternalPage: false,
  },
  {
    title: "FAQ",
    linkTo: "/faq",
    linkToExternalPage: false,
  },
];

const navMenu2 = [
  {
    title: "RARITIES",
    linkTo: "/rarities",
    linkToExternalPage: false,
  },
  {
    title: "STAKE",
    linkTo: "https://stake.ggnz.io/",
    linkToExternalPage: true,
  },
];

function Header({
  isHome,
  handleClickGoToHome,
  handleClickGoToRoadMap,
  handleClickGoToTeam,
  handleClickGoToFaq,
  changePage,
}) {
  const [windowWidth] = useWindowSize();

  const [showDrawer, setShowDrawer] = useState(false);

  const handleOpenDrawer = () => setShowDrawer(true);
  const handleCloseDrawer = () => setShowDrawer(false);

  return (
    <>
      {windowWidth > 1100 ? (
        <header className={styles.headerWrapper}>
          <div className={styles.navWrapper}>
            <div className={styles.logoWrapper}>
              <img className={styles.logoImg} src={ggnzLogo} alt="ggnz_logo" />
              <h1 className={styles.logoText}>GAEGUNEEZ</h1>
            </div>
            <nav className={styles.navItemWrapper}>
              {navMenu.map((menuItem) => {
                if (!isHome) {
                  return (
                    <div className={styles.navItem} key={menuItem.title}>
                      <a href={`/`}>{menuItem.title}</a>
                    </div>
                  );
                }

                return (
                  <div
                    className={styles.navItem}
                    key={menuItem.title}
                    onClick={() => {
                      if (menuItem.title === "HOME") {
                        changePage(true);
                        handleClickGoToHome();
                      }
                      if (menuItem.title === "ROADMAP") {
                        changePage(true);
                        handleClickGoToRoadMap();
                      }
                      if (menuItem.title === "TEAM") {
                        changePage(true);
                        handleClickGoToTeam();
                      }
                      if (menuItem.title === "FAQ") {
                        changePage(true);

                        handleClickGoToFaq();
                      }
                    }}
                  >
                    {menuItem.title}
                  </div>
                );
              })}
              <div className={styles.navVerticalLine}></div>
              {navMenu2.map((menuItem) => {
                if (!isHome) {
                  return (
                    <div className={styles.navItem} key={menuItem.title}>
                      <a href={`/`}>{menuItem.title}</a>
                    </div>
                  );
                }

                return (
                  <div
                    className={styles.navItem}
                    key={menuItem.title}
                    onClick={() => {
                      if (menuItem.title === "RARITIES") changePage(false);

                      if (menuItem.title === "STAKE")
                        window.open("https://stake.ggnz.io");
                    }}
                  >
                    <div>{menuItem.title}</div>
                    {menuItem.title === "STAKE" && (
                      <img src={outlink} alt="" className={styles.outlinkImg} />
                    )}
                  </div>
                );
              })}
            </nav>
          </div>
        </header>
      ) : (
        <>
          <header className={styles.mobileWrapper}>
            <div className={styles.mobileNavWrapper}>
              <div className={styles.mobileLogoWrapper}>
                <div>
                  <img
                    className={styles.mobileLogoImg}
                    src={ggnzLogo}
                    alt="ggnz_logo"
                  />
                </div>

                <h1 className={styles.mobileLogoText}>GAEGUNEEZ</h1>
              </div>

              <div className={styles.hamburger} onClick={handleOpenDrawer}>
                <img src={hamburger} alt="hamburger" width={30} height={30} />
              </div>
            </div>
          </header>
          <Drawer anchor="left" open={showDrawer} onClose={handleCloseDrawer}>
            <div style={{ width: windowWidth * 0.7, padding: "50px 20px" }}>
              {[...navMenu, ...navMenu2].map((menuItem) => {
                return (
                  <div
                    className={styles.mobileNavItem}
                    key={menuItem.title}
                    onClick={() => {
                      if (menuItem.title === "HOME") {
                        changePage(true);
                        handleClickGoToHome();
                        handleCloseDrawer();
                      }
                      if (menuItem.title === "ROADMAP") {
                        changePage(true);
                        handleClickGoToRoadMap();
                        handleCloseDrawer();
                      }
                      if (menuItem.title === "TEAM") {
                        changePage(true);
                        handleClickGoToTeam();
                        handleCloseDrawer();
                      }
                      if (menuItem.title === "FAQ") {
                        changePage(true);

                        handleClickGoToFaq();
                        handleCloseDrawer();
                      }
                      if (menuItem.title === "RARITIES") {
                        changePage(false);
                        handleCloseDrawer();
                      }

                      if (menuItem.title === "STAKE") {
                        window.open("https://stake.ggnz.io");
                        handleCloseDrawer();
                      }
                    }}
                  >
                    {menuItem.title}
                  </div>
                );
              })}
            </div>
          </Drawer>
        </>
      )}
    </>
  );
}

export default Header;
